import React from "react";
import styles from "./promoBox.module.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

class PromoBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      background: this.props.dynamicStyles.promobox.backgroundImage,
      backgroundColor: this.props.dynamicStyles.promobox.backgroundColor,
    };
  }
  changeBackgroundToMobileImage(img) {
    if (!img) return;
    if (window.innerWidth < 640) {
      if (this.state.background !== img) {
        this.setState({ background: img });
      }
    }
  }
  changeBackgroundToDesktopImage(img) {
    if (!img) return;
    if (window.innerWidth >= 1261) {
      if (this.state.background !== img) {
        this.setState({ background: img });
      }
    }
  }
  changeBackgroundToTabletImage(img) {
    if (!img) return;
    if (window.innerWidth >= 768 && window.innerWidth <= 1260) {
      if (this.state.background !== img) {
        this.setState({ background: img });
      }
    }
  }
  componentDidMount() {
    const backgroundChoice = this.props.dynamicStyles.promobox.backgroundChoice;
    const backgroundImage = this.props.dynamicStyles.promobox.backgroundImage;
    const backgoundImageMobile = this.props.dynamicStyles.promobox
      .backgroundMobileImage;
    const backgroundImageTablet = this.props.dynamicStyles.promobox
      .backgroundTabletImage;

    if (typeof window !== `undefined`) {
      if (backgroundChoice !== "Color") {
        window.addEventListener("resize", () =>
          this.changeBackgroundToDesktopImage(backgroundImage)
        );
        window.addEventListener("resize", () =>
          this.changeBackgroundToMobileImage(backgoundImageMobile)
        );
        window.addEventListener("resize", () =>
          this.changeBackgroundToTabletImage(backgroundImageTablet)
        );
        window.addEventListener("load", () =>
          this.changeBackgroundToDesktopImage(backgroundImage)
        );
        window.addEventListener("load", () =>
          this.changeBackgroundToMobileImage(backgoundImageMobile)
        );
        window.addEventListener("load", () =>
          this.changeBackgroundToTabletImage(backgroundImageTablet)
        );
      }
    }
  }
  render() {
    const backgroundChoice = this.props.dynamicStyles.promobox.backgroundChoice;

    return (
      <div>
        {backgroundChoice === "Color" ? (
          <div
            className={styles.promoBox}
            style={{
              backgroundColor: this.state.backgroundColor,
            }}
          >
            {/* <p>
              {documentToReactComponents(
                this.props.contentPromoBox[0].promotionText.json
              )}
            </p> */}
            <p>
              <strong>{this.props.contentPromoBox[0].promotionCode}</strong>
            </p>
          </div>
        ) : (
          <div>
            <img
              className={styles.tabletView}
              src={this.state.background}
            ></img>
          </div>
        )}
      </div>
    );
  }
}
export default PromoBox;
