import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import whoWeAreStyles from "./sectionWhoWeAre.module.css";

class SectionWhoWeAre extends React.Component {
  render() {
    const contentText = this.props.result.paragraph.json;
    const teamMembers = this.props.result.teamMembers
      ? this.props.result.teamMembers
      : undefined;
    const bodyTheme = this.props.bodyTheme;
    const showTeamMemberList = this.props.result.showTeamMemberList;
    return (
      <div className={`${bodyTheme}Text`}>
        {documentToReactComponents(contentText)}
        <div>
          {showTeamMemberList ? (
            teamMembers.map((member) => {
              return (
                <div className={whoWeAreStyles.contentTop}>
                  <div className={whoWeAreStyles.imageWrapper}>
                    <img src={member.profileImage.file.url} alt="" />
                  </div>
                  <div className={whoWeAreStyles.profileText}>
                    <div className={`${bodyTheme}Text`}>
                      <p>{documentToReactComponents(member.bio.json)}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className={whoWeAreStyles.teamPhotoWrapper}>
              <img src={teamMembers[0].profileImage.file.url} alt="" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default SectionWhoWeAre;
