import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../components/heroSlider.css";
import ResponsivePlayer from "../components/responsivePlayer";

class HeroSlider extends React.Component {
  render() {
    var settings = {
      accessibility: true,
      autoplay: false,
      autoplayspeed: 3000,
      dots: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true
    };
    const imgs = this.props.slides.images;
    const youtubeLink = this.props.youtubeLink;
    let video = null 

    if (!this.props.slides) {
      return null;
    }
    if(youtubeLink){
      video = (<ResponsivePlayer url={youtubeLink}></ResponsivePlayer>)
    }
    return (
      <div className={this.props.className}>
        <Slider {...settings}>
          {youtubeLink? video:null}
          {imgs.map((node, i) => {
            return (
              <img key={`img_${i}`} alt={`img_${i}`} src={node.file.url}></img>
            );
          })}
        </Slider>
      </div>
    );
  }
}

export default HeroSlider;
