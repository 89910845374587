import React from "react";
import HeroSlider from "../../components/heroSlider";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Styles from "./sectionHome.module.css";
import ResponsivePlayer from "../../components/responsivePlayer";
class SectionHome extends React.Component {
  render() {
    const contentText = this.props.result.tagline.json;
    const heroSliderContent = this.props.result;
    const images = heroSliderContent.heroSlider.images;
    const youtubeVideo = heroSliderContent.heroSlider.youtubeLink;
    const bodyTheme = this.props.bodyTheme;
    let content = null;
    if (images === null) {
      content = (
        <div className={Styles.Slider}>
          <ResponsivePlayer url={youtubeVideo}></ResponsivePlayer>
        </div>
      );
    } else {
      content = (
        <HeroSlider
          className={Styles.Slider}
          slides={heroSliderContent.heroSlider}
          youtubeLink={youtubeVideo}
        />
      );
    }

    return (
      <div className={Styles.heroSliderVideo}>
        <div className={Styles.tagline}>
          <div className={`${bodyTheme}Text`}>
            {documentToReactComponents(contentText)}
          </div>
        </div>
        {content}
      </div>
    );
  }
}

export default SectionHome;
