import React from "react";
import get from "lodash/get";
import styles from "./packPreview.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { faPauseCircle } from "@fortawesome/free-solid-svg-icons";

export default ({ pack, togglePlay, playerInfo, audios }) => {
  const audioUrl = get(pack, "audios[0].file.url");
  const { playing, playingAudioUrl } = playerInfo;
  const isThisPackPlaying = playing && playingAudioUrl === audioUrl;
  const reducingWarnings = () => {
    return;
  };

  return (
    <div>
      <div className={styles.wrapper}>
        {audioUrl ? (
          <FontAwesomeIcon
            role="button"
            className={styles.icon}
            icon={isThisPackPlaying ? faPauseCircle : faPlayCircle}
            onClick={() => togglePlay(audioUrl)}
            onKeyDown={reducingWarnings}
          />
        ) : null}

        <img
          alt="prev"
          className={styles.image}
          src={pack.coverImage.file.url}
        />
      </div>

      <div role="contentinfo" className={styles.previewTitle}>
        {pack.title}
      </div>
    </div>
  );
};
