import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Helmet from "react-helmet";
import PackGrid from "../components/packGrid";
import Layout from "../layouts";
import SectionHome from "../sections/sectionHome/sectionHome";
import SectionSamplePack from "../sections/sectionSamplePacks/sectionSamplePacks";
import SectionWhoWeAre from "../sections/sectionWhoWeAre/sectionWhoWeAre";
import SectionGetInTouch from "../sections/sectionGetInTouch/sectionGetInTouch";
import IndexStyles from "./index.module.css";
import headerStyle from "../layouts/header.module.css";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isButtonClose: true,
      numberOfPacks: 5,
      packs: get(this, "props.data.contentfulSectionSamplePacks")
        .packs.filter((pack) => pack.featured === true)
        .slice(0, 5),
      textButton: "More",
    };
  }

  moreButton = () => {
    let featuredPacks = get(
      this,
      "props.data.contentfulSectionSamplePacks"
    ).packs.filter((pack) => pack.featured === true);
    let standardPacks = get(
      this,
      "props.data.contentfulSectionSamplePacks"
    ).packs.filter((pack) => pack.featured === false);
    let numberPacks;
    if (window.innerWidth >= 1198) {
      numberPacks = 5;
    } else if (window.innerWidth <= 1197 && window.innerWidth >= 946) {
      numberPacks = 4;
    } else {
      numberPacks = 3;
    }

    if (!this.state.isButtonClose) {
      document.getElementById("samplePacks").scrollIntoView();
      this.setState({
        numberOfPacks: numberPacks,
        isButtonClose: true,
        packs: featuredPacks.slice(0, numberPacks),
        textButton: "More",
      });
    } else {
      this.setState({
        isButtonClose: false,
        packs: [...featuredPacks, ...standardPacks],
        textButton: "Less",
      });
    }
  };
  changeTabletViewVertical() {
    if (window.innerWidth <= 945) {
      this.setState({
        numberOfPacks: 3,
        packs: get(this, "props.data.contentfulSectionSamplePacks")
          .packs.filter((pack) => pack.featured === true)
          .slice(0, 3),
      });
    }
  }
  changeTransition() {
    if (window.innerWidth >= 1198) {
      this.setState({
        numberOfPacks: 5,
        packs: get(this, "props.data.contentfulSectionSamplePacks")
          .packs.filter((pack) => pack.featured === true)
          .slice(0, 5),
      });
    }
  }
  changeTabletViewHorizontal() {
    if (window.innerWidth <= 1197 && window.innerWidth >= 946) {
      this.setState({
        numberOfPacks: 4,
        packs: get(this, "props.data.contentfulSectionSamplePacks")
          .packs.filter((pack) => pack.featured === true)
          .slice(0, 4),
      });
    }
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", () => {
        this.changeTabletViewVertical();
      });
      window.addEventListener("load", () => {
        this.changeTabletViewVertical();
      });
      window.addEventListener("resize", () => {
        this.changeTabletViewHorizontal();
      });
      window.addEventListener("load", () => {
        this.changeTabletViewHorizontal();
      });
      window.addEventListener("resize", () => {
        this.changeTransition();
      });
      window.addEventListener("load", () => {
        this.changeTransition();
      });
    }
  }
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const imgLogoSrc = get(
      this,
      "props.data.contentfulCompanyLogo.logo.file.url"
    );

    const siteConfigResult = get(
      this,
      "props.data.contentfulSiteConfiguration"
    );

    const promoboxConfiguration = get(
      this,
      "props.data.contentfulSectionSamplePacks.promobox[0].promoboxConfiguration"
    );
    const sectionHomeQuery = get(this, "props.data.contentfulSectionHome");
    const sectionSamplePackQuery = get(
      this,
      "props.data.contentfulSectionSamplePacks"
    );
    const sectionWhoWeAreQuery = get(
      this,
      "props.data.contentfulSectionWhoWeAre"
    );
    const sectionGetInTouchQuery = get(
      this,
      "props.data.contentfulSectionGetInTouch"
    );
    const bodyTheme = get(
      this,
      "props.data.contentfulSiteConfiguration.bodyTheme"
    );
    const dynamicStyles = {
      siteConfigs: {
        font: siteConfigResult.fonts,
        backgroundColor: siteConfigResult.colorHex,
        backgroundImage: `url(${siteConfigResult.backgroundImage.file.url})`,
        backgroundChoice: siteConfigResult.backgroundChoice,
        footer: siteConfigResult.footer,
      },
      promoboxConfigs: {
        buttons: {
          buttonColor: `#${promoboxConfiguration.buttonColor}`,
          buttonBorder: `4px solid #${promoboxConfiguration.ButtonBorderColor}`,
          buttonTextColor: `#${promoboxConfiguration.buttonTextColor}`,
        },
        promobox: {
          backgroundColor: `#${promoboxConfiguration.promoBoxColor}`,
          textColor: `#${promoboxConfiguration.promoBoxTextColor}`,
          backgroundImage: get(
            promoboxConfiguration,
            "promoBoxBackgroundImage.file.url"
          ),
          backgroundTabletImage: get(
            promoboxConfiguration,
            "promoTabletBanner.resize.src"
          ),
          backgroundMobileImage: get(
            promoboxConfiguration,
            "promoMobileBanner.resize.src"
          ),
          backgroundChoice: promoboxConfiguration.backgroundChoice,
        },
      },
      samplePackSectionButton: {
        buttonColor: `#${sectionSamplePackQuery.configuration.buttonTextColor}`,
        buttonTextColor: `#${sectionSamplePackQuery.configuration.bottonColor}`,
        borderButton: `4px solid #${sectionSamplePackQuery.configuration.buttonBorderColor}`,
      },
    };
    if (siteConfigResult === undefined) {
      return null;
    } else
      return (
        <Layout
          imgSrc={imgLogoSrc}
          dynamicStyles={dynamicStyles.siteConfigs}
          className={`${dynamicStyles.siteConfigs.bodyTheme}Text`}
        >
          <Helmet title={siteTitle} />

          <div className={headerStyle.sections}>
            <section className={IndexStyles.sectionWrapper}>
              <div className={headerStyle.clearBox} id="home"></div>
              <SectionHome result={sectionHomeQuery} bodyTheme={bodyTheme} />
            </section>

            <section className={IndexStyles.sectionWrapper}>
              <div className={headerStyle.clearBox} id="samplePacks"></div>
              <div className="section-headline">
                <h1 className={`${bodyTheme}Text`}>
                  {sectionSamplePackQuery.sectionHeadline}
                </h1>
              </div>
              <div className={headerStyle.contentP}>
                <SectionSamplePack
                  result={sectionSamplePackQuery}
                  dynamicStyles={dynamicStyles.promoboxConfigs}
                  bodyTheme={bodyTheme}
                >
                  <PackGrid packs={this.state.packs} bodyTheme={bodyTheme} />

                  <div className="wrapper">
                    <button
                      className="seeall-btn"
                      onClick={this.moreButton}
                      style={{
                        backgroundColor:
                          dynamicStyles.samplePackSectionButton.buttonColor,
                        color:
                          dynamicStyles.samplePackSectionButton.buttonTextColor,
                        border:
                          dynamicStyles.samplePackSectionButton.borderButton,
                      }}
                    >
                      {this.state.textButton}
                    </button>
                  </div>
                </SectionSamplePack>
              </div>
            </section>
            <section className={IndexStyles.sectionWrapper}>
              <div className={headerStyle.clearBox} id="WhoWeAre"></div>
              <div className="section-headline">
                <h1 className={`${bodyTheme}Text`}>
                  {sectionWhoWeAreQuery.sectionHeadline}
                </h1>
              </div>
              <div className={headerStyle.contentP}>
                <SectionWhoWeAre
                  result={sectionWhoWeAreQuery}
                  bodyTheme={bodyTheme}
                />
              </div>
            </section>
            <section className={IndexStyles.sectionWrapper}>
              <div className={headerStyle.clearBox} id="GetInTouch"></div>
              <div className="section-headline">
                <h1 className={`${bodyTheme}Text`}>
                  {sectionGetInTouchQuery.sectionHeadline}
                </h1>
              </div>
              <div className={headerStyle.contentP}>
                <SectionGetInTouch
                  result={sectionGetInTouchQuery}
                  dynamicStyles={dynamicStyles.siteConfigs}
                  bodyTheme={bodyTheme}
                />
              </div>
            </section>
          </div>
        </Layout>
      );
  }
}

export default Index;

export const pageQuery = graphql`
  query PacksIndexQuery {
    contentfulSiteConfiguration {
      fonts
      colorHex
      backgroundImage {
        file {
          url
        }
      }
      headerTheme
      bodyTheme
      footerTheme
      footer {
        json
      }
      favicon {
        file {
          url
        }
      }
      backgroundChoice
    }
    contentfulCompanyLogo {
      logo {
        file {
          url
        }
      }
    }
    contentfulSectionHome {
      sectionHeadline
      tagline {
        json
      }

      heroSlider {
        images {
          file {
            url
          }
        }
        youtubeLink
      }
    }
    contentfulSectionSamplePacks {
      sectionHeadline
      paragraph {
        json
      }
      packs {
        title
        audios {
          title
          file {
            url
          }
        }
        slug
        coverImage {
          file {
            url
          }
          resize(width: 300) {
            src
            width
            height
          }
        }
        featured
        description {
          json
        }
        details {
          json
        }
        link
      }
      promobox {
        promoboxConfiguration {
          backgroundChoice
          promoBoxBackgroundImage {
            file {
              url
            }
          }
          promoTabletBanner {
            resize(width: 768) {
              src
              width
              height
            }
          }
          promoMobileBanner {
            resize(width: 300) {
              src
              width
              height
            }
          }
          buttonColor
          ButtonborderColor
          buttonTextColor
          promoBoxColor
          promoBoxTextColor
        }
        promotionText {
          json
        }
        link1
        link2
        promotionCode
      }
      configuration {
        buttonBorderColor
        bottonColor
        buttonTextColor
      }
    }

    contentfulSectionWhoWeAre {
      sectionHeadline
      paragraph {
        json
      }
      teamMembers {
        profileImage {
          file {
            url
          }
        }
        bio {
          json
        }
      }
      showTeamMemberList
    }
    contentfulSectionGetInTouch {
      sectionHeadline
      paragraph {
        json
      }
      eMail
      hyperlink1
      hyperlink2
    }
  }
`;
