import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import PromoBox from "../../components/promoBox";
import styles from "../../components/promoBox.module.css";
class SectionSamplePacks extends React.Component {
  render() {
    const contentText = this.props.result.paragraph.json;
    const promoBox = this.props.result.promobox;
    const dynamicStyles = this.props.dynamicStyles;
    const bodyTheme = this.props.bodyTheme;

    return (
      <div className={`${bodyTheme}Text`}>
        {documentToReactComponents(contentText)}
        {this.props.children}
        <PromoBox
          contentPromoBox={promoBox}
          dynamicStyles={dynamicStyles}
          bodyTheme={bodyTheme}
        />

        <div className={styles.buttonContainer}>
          <a href={promoBox[0].link1}>
            <button
              className={styles.buttons}
              style={{
                backgroundColor: dynamicStyles.buttons.buttonColor,
                color: dynamicStyles.buttons.buttonTextColor,
                border: dynamicStyles.buttons.buttonBorder,
              }}
            >
              Learn More
            </button>
          </a>
          <a href={promoBox[0].link2}>
            <button
              className={styles.buttons}
              style={{
                backgroundColor: dynamicStyles.buttons.buttonColor,
                color: dynamicStyles.buttons.buttonTextColor,
                border: dynamicStyles.buttons.buttonBorder,
              }}
            >
              Go to Splice
            </button>
          </a>
        </div>
      </div>
    );
  }
}
export default SectionSamplePacks;
