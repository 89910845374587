import React, { useState, useEffect } from "react";
import PackPreview from "./packPreview";
import styles from "./packGrid.module.css";
const isSSR = typeof window === "undefined";
const mapAudios = new Map();

let audio = null;

const useAudio = () => {
  const [playing, setPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const toggle = (url) => {
    if (audioUrl !== url) {
      setPlaying(true);
      setAudioUrl(url);
      playMusic(true, url);
    } else {
      setPlaying(!playing);
      playMusic(!playing, audioUrl);
    }
  };

  const playMusic = (playing, url) => {
    if (audio) {
      if (audio.src !== url) {
        audio.pause();
        if (mapAudios.get(url)) {
          audio = mapAudios.get(url);
        } else {
          audio = new Audio(url);
          mapAudios.set(url, audio);
        }
      }
      playing ? audio.play() : audio.pause();
    } else if (url) {
      audio = new Audio(url);
      mapAudios.set(url, audio);
      audio.play();
    }
  };

  useEffect(() => {
    if (audio) {
      audio.addEventListener("ended", () => setPlaying(false));
      return () => {
        audio.removeEventListener("ended", () => setPlaying(false));
      };
    }
  }, []);

  return [playing, audioUrl, toggle, mapAudios];
};

export default ({ packs, bodyTheme }) => {
  if (isSSR) return null;
  const [playing, playingAudioUrl, toggle, mapAudios] = useAudio();
  return (
    <ul className={styles.packGrid}>
      {packs.map((pack, i) => (
        <div key={`pack_${i}`} className={styles.packContainer}>
          <li>
            <PackPreview
              bodyTheme={bodyTheme}
              pack={pack}
              togglePlay={toggle}
              playerInfo={{ playingAudioUrl, playing }}
              audios={mapAudios}
            />
          </li>
        </div>
      ))}
    </ul>
  );
};
