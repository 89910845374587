import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES } from "@contentful/rich-text-types";

class SectionGetInTouch extends React.Component {
  render() {
    const contentText = this.props.result.paragraph.json;
    const email = this.props.result.eMail;
    const bodyTheme = this.props.bodyTheme;
    const options = {
      renderNode: {
        [INLINES.HYPERLINK]: (node, children) => {
          return (
            <a href={node.data.uri} target="_blank">
              {children}
            </a>
          );
        },
      },
    };

    return (
      <div className={`${bodyTheme}Text`}>
        {documentToReactComponents(contentText, options)}
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    );
  }
}
export default SectionGetInTouch;
